import * as JSZip from 'jszip';
import {saveAs} from 'file-saver';


function getBaseUrl(){
  const isLocal = window.location.host.includes('localhost');
  const localPublic = 'http://localhost:3000';
  const localProd = 'https://nihss.plus';
  return isLocal ? localPublic : localProd;
}

/**
 * @param secret
 */
function getAu(secret, orderSecret, formattedName) {
  return `"system_id","type","command_line","Max_Time_Allowed","time_limit_action","file_name","max_score","mastery_score","system_vendor","core_vendor","web_launch","au_password"
  "A1","course","web_launch","00:00:00","","${getBaseUrl()}/lms/auth","100","93","","","orderSecret=${orderSecret}&orgSecret=${secret}&courseID=254&clientID=${formattedName}",""`;
}

/**
 *
 */
function getCrs() {
  const contents = `[Course]
    Course_Creator=Apex Innovations
    Course_ID=15
    Course_System=HTML
    Course_Title=NIHSS+
    Level=1
    Max_Fields_CST=2
    Total_AUs=1
    Total_Blocks=0
    Version=3.0
    [Course_Behavior]
    Max_Normal=1
    [Course_Description]
    This class is the National Institute of Health Stroke Scale (NIHSS) Training and Certification.`;

  return contents;
}

/**
 *
 */
function getCst() {
  const contents = `"block","member"
                    "ROOT","A1"`;
  return contents;
}

/**
 *
 */
function getDes() {
  const contents = `"system_id","developer_id","title","description"
                        "A1","1","Title","Description"`;

  return contents;
}

function formatOrgName(name) {
 //no white spaces, no special characters, all uppercase
  return name.replace(/\s/g, '').toUpperCase();
}

/**
 * @param name
 * @param secret
 */
const generateManifest = (name, secret, orderSecret) => {
  const zip = new JSZip();
  const formattedName = formatOrgName(name);

  zip.file('course.au', getAu(secret, orderSecret, formattedName));
  zip.file('course.crs', getCrs());
  zip.file('course.cst', getCst());
  zip.file('course.des', getDes());

  zip.generateAsync({type: 'blob'})
      .then((content)=> {
        saveAs(content, name.replace(' ', '') + '_NIHSSManifest.zip');
      });
};

export default generateManifest;
