var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex md12"},[_c('va-card',{attrs:{"title":'Organizations - Orders'}},[_c('div',{staticClass:"row align--center"},[_c('div',{staticClass:"flex xs12 md3"},[_c('va-input',{attrs:{"value":_vm.term,"placeholder":'Search Replies',"removable":""},on:{"input":_vm.search}},[_c('va-icon',{attrs:{"slot":"prepend","name":"fa fa-search"},slot:"prepend"})],1)],1),_c('div',{staticClass:"flex xs12 md3 mb-3"},[_c('va-button',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.CreateOrderForm()}}},[_c('i',{staticClass:"va-icon iconicstroke iconicstroke-bolt",staticStyle:{"margin-right":"1em"}}),_vm._v(" Create Order ")])],1),_c('div',{staticClass:"flex xs12 md3 offset--md3"},[_c('va-select',{attrs:{"label":_vm.$t('tables.perPage'),"options":_vm.perPageOptions,"no-clear":""},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1)]),_c('va-data-table',{attrs:{"fields":_vm.fields,"data":_vm.filteredData,"per-page":parseInt(_vm.perPage),"loading":_vm.loading,"clickable":"","hoverable":""},scopedSlots:_vm._u([{key:"orderSecret",fn:function(props){return [_c('span',{attrs:{"title":props.rowData.formatedOS},on:{"click":function($event){return _vm.showField('Order Code', props.rowData.formatedOS)}}},[_vm._v(_vm._s(props.rowData.formatedOS))])]}},{key:"price",fn:function(props){return [_c('span',{attrs:{"title":props.rowData.default.price},on:{"click":function($event){return _vm.showField('Price', props.rowData.default.price)}}},[_vm._v(_vm._s(props.rowData.price))])]}},{key:"purchasedSubscriptions",fn:function(props){return [_c('span',{attrs:{"title":props.rowData.default.purchasedSubscriptions},on:{"click":function($event){return _vm.showField(
                'Number of Subscribers',
                props.rowData.default.purchasedSubscriptions
              )}}},[_vm._v(_vm._s(props.rowData.purchasedSubscriptions))])]}},{key:"createdAt",fn:function(props){return [_c('span',{attrs:{"title":props.rowData.default.createdAt},on:{"click":function($event){return _vm.showField('Created At', props.rowData.default.createdAt)}}},[_vm._v(_vm._s(props.rowData.createdAt))])]}},{key:"manifest",fn:function(props){return [_c('va-button',{attrs:{"title":"Share Manifest","color":"info","icon":"fa fa-share"},on:{"click":function($event){return _vm.shareManifest(props.rowData)}}},[_vm._v(" Share Manifest ")])]}},{key:"actions",fn:function(props){return [_c('div',{staticClass:"flex row"},[_c('va-button',{style:(props.rowData.default.hardStop ? {} : { opacity: 0.5 }),attrs:{"title":"Hard Stop","color":"#c18322","icon":"fa fa-flag"},on:{"click":function($event){return _vm.HardStopOrder(props.rowData)}}}),_c('va-button',{attrs:{"title":"View/Edit Order","icon":"fa fa-pencil"},on:{"click":function($event){return _vm.EditOrderForm(props.rowData)}}}),_c('va-button',{attrs:{"title":"Share Order Information","icon":"fa fa-share"},on:{"click":function($event){return _vm.ShareOrderInformation(props.rowData)}}}),_c('va-button',{attrs:{"title":"Related Users","icon":"fa fa-users"},on:{"click":function($event){return _vm.ShowRelatedUsers(props.rowData)}}}),_c('va-button',{attrs:{"title":"Delete Order","color":"danger","icon":"fa fa-trash"},on:{"click":function($event){return _vm.DeleteOrderForm(
                  props.rowData.id,
                  props.rowData.default.orderSecret
                )}}})],1)]}}])}),_c('va-button',{staticClass:"mb-1 mt-3",attrs:{"color":"primary"},on:{"click":function($event){return _vm.back()}}},[_c('i',{staticClass:"va-icon iconicstroke iconicstroke-arrow-left",staticStyle:{"margin-right":"1em"}}),_vm._v(" Back ")])],1),_c('va-modal',{staticClass:"flex xs12",attrs:{"hideDefaultActions":"","title":"Related Users for Order","size":"large","ok-text":"Ok"},model:{value:(_vm.RelatedUsersModal),callback:function ($$v) {_vm.RelatedUsersModal=$$v},expression:"RelatedUsersModal"}},[_c('va-card',[_c('va-data-table',{attrs:{"fields":_vm.relatedUsersField,"data":_vm.relatedUsersData,"per-page":parseInt(_vm.perPage),"loading":_vm.loading,"clickable":"","hoverable":""},scopedSlots:_vm._u([{key:"name",fn:function(props){return [_c('span',{attrs:{"title":props.rowData.displayName},on:{"click":function($event){return _vm.showField('Name', props.rowData.displayName)}}},[_vm._v(_vm._s(props.rowData.displayName))])]}},{key:"email",fn:function(props){return [_c('span',{attrs:{"title":props.rowData.email},on:{"click":function($event){return _vm.showField('Email', props.rowData.email)}}},[_vm._v(_vm._s(props.rowData.email))])]}},{key:"joinDate",fn:function(props){return [_c('span',{attrs:{"title":props.rowData.organizationData.formatedJoinDate},on:{"click":function($event){return _vm.showField(
                  'JoinedAt',
                  props.rowData.organizationData.formatedJoinDate
                )}}},[_vm._v(_vm._s(props.rowData.organizationData.formatedJoinDate))])]}},{key:"actions",fn:function(props){return [_c('div',{staticClass:"flex row"},[_c('va-button',{attrs:{"title":"User Page","color":"success","icon":"fa fa-user"},on:{"click":function($event){return _vm.redirectUser(props.rowData)}}}),_c('va-button',{attrs:{"title":"Unassign User","color":"danger","icon":"fa fa-trash"},on:{"click":function($event){return _vm.UnassignUser(props.rowData)}}})],1)]}}])})],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }